/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.menu-toggle').click(function(){
          $('.hamburger', this).toggleClass('is-active');
          $('.hamburger-container', this).toggleClass('is-active');
          $('.menu-toggle-content').slideToggle();
        });

        $('.item-slider').slick({
          nextArrow: '<span class="slick-arrow arrow-right btn btn-secondary btn-icon"><i class="fas fa-chevron-right"></i></span>',
          prevArrow: '<span class="slick-arrow arrow-left btn btn-secondary btn-icon"><i class="fas fa-chevron-left"></i></span>',
          dots: true,
          autoplay: true,
          autoplaySpeed: 5000
        });

        $('.scroll2top').click(function(){
          scrollToElement($('html'));
        });

        function scrollToElement($element) {
          var position = $element.position().top;
          $("html, body").animate({ scrollTop: position }, 300);
          return false;
        }

        function initTabs($tabsContainer) {
          var tabs = [],
            $section = $tabsContainer.parent(),
            $tabsNav = $section.find('.tab-nav'),
            tabsNavHtml = '<li class="nav-item"><a class="{{active}}" href="#{{id}}" data-toggle="pill">{{tab-name}}</a></li>';

          // fill tab array with tab information
          $tabsContainer.find('.tab').each(function(){
            var tab = [];
            tab.id = $(this).attr('id');
            tab.name = $(this).attr('tab-name');
            tabs.push(tab);
          });
          // end

          // add all nav tabs
          for (var i = 0; i < tabs.length; i++) {
            tabsNavHtml = '<li class="nav-item"><a href="#{{id}}" data-toggle="pill">{{tab-name}}</a></li>';
            tabsNavHtml = tabsNavHtml.replace('{{tab-name}}',tabs[i].name);
            tabsNavHtml = tabsNavHtml.replace('{{id}}',tabs[i].id);
            $tabsNav.append(tabsNavHtml);
          }
          // end
          
          // add show all tabs
          if($('.item.item-project').length){
            //$tabsNav.prepend('<li class="nav-item"><a href="#all" data-toggle="pill">Alles</a></li>');
          }
          // end  
           
          // add active class state to the first nav item
          $tabsNav.find('.nav-item:first-child a').addClass('active');
          // end
        }

        function initAllButton($tabsContainer) {
          var $section = $tabsContainer.parent(),
            $tabsNav = $section.find('.tab-nav'),
            $allTabsActive = 0;

          $tabsNav.find('a').click(function(){
            if($(this).attr('href') === '#all') {
              $section.find('.tab-content .tab').addClass('active');
              $allTabsActive = 1;
            }else {
              if($allTabsActive === 1) {
                $section.find('.tab-content .tab.active').removeClass('active');
                $allTabsActive = 0;
                $section.find('.tab-content .tab'+$(this).attr('href')).addClass('active');
              }
            }
          });
        }

        $('main.main > .tab-content').each(function(){
          initTabs($(this));
          initAllButton($(this));
        });

        // Initialize global var Downloadoption which is filled in the selectbox change function of the corresponding properties
        var downloadOption = {bestandtype:'',categorie:'',product:'', stap: 0, bestandenLoaded: false, isProductPage : false};
        // downloadOption.stap:
        //   1 = bestandtype selected
        //   2 = bestandtype and categorie selected
        //   3 = bestandtype and categorie and product selected

        var bestandtypeToShow = ['1','2'];

        // Ajax function get posts
        function get_posts($params) {
          $container = $('#container-async');
          $content   = $container.find('.content');
          $status    = $container.find('.status');
          $status.text('Loading posts ...');
          $contactform = $('.download-tool .download-tool__contact-form');
          $.ajax(
          {
            url: bobz.ajax_url,
            data: {
              action: $params['action'],
              nonce: bobz.nonce,
              params: $params
            },
            type: 'post',
            dataType: 'json',
            success: function(data, textStatus, XMLHttpRequest) {
              if (data.status === 200) {
                hideContactForm($contactform.closest('section.section'));
                $productGroepHtml = $contactform.parent().find('.eigenschappen-inputs-container');

                if($params.action !== 'do_create_filter_selectboxes') {
                  $content.html(data.content);
                }

                // Fill the product selectbox with options
                if($params.getProducten) {
                  convertProductOptions($content);
                  $productGroepHtml.parent().find('.producten-select-container').show();
                }

                if($params.getBestanden) {
                  // Fill the bestanden section or show contact form if no products are found
                  if(data.found) {
                    downloadOption.bestandenLoaded = true;
                    showBestanden($('.download-tool').closest('section.section'));
                  }else {
                    handleEmptyBestanden();
                  }
                }
                if($params.action === 'do_create_filter_selectboxes'){
                  if(data.found) {
                    cleanProductGroepHtml($productGroepHtml);
                    $productGroepHtml.html(data.content);
                    if($productGroepHtml.find('.eigenschap-select').length) {
                      $item = '';
                      $productGroepHtml.find('.eigenschap-select').each(function(){
                        $item = $(this);
                        return false;
                      });
                      $item.show();
                    }else {
                      get_models_from_eigenschappen();
                    }
                  }
                }
              }
              else {
                $('select[name="product"]').closest('.row').hide();
                handleEmptyBestanden();
                $status.html(data.message);
              }
            },
            error: function(MLHttpRequest, textStatus, errorThrown) {
              $status.html(textStatus);
            },
            complete: function(data, textStatus) {
              msg = textStatus;
              if (textStatus === 'success') {
                msg = data.responseJSON.found;
              }
              $status.text('Posts found: ' + msg);
            }
          });
        }
        function cleanProductGroepHtml($productGroepHtml) {
          $productGroepHtml.find('.eigenschap-select').remove();
          $productGroepHtml.find('input[type="hidden"][name^="productgroep"]').remove();
        }
        function clearAjaxProducts() {
          $('.bestand-select select[name="product"]').closest('.bestand-select').hide();
          $('#container-async').hide();
        }

        function fillProductOptions($options) {
          // Fill the product selectbox with options from the filtered Ajax response from "convertProductOptions()"
          var html = '<option disabled="disabled" selected="selected" value="">Maak een keuze</option>',
          $productSelectbox = $('.download-tool select[name="product"]');
          for (var i = 0; i < $options.length; i++) {
            html += '<option value="'+$options[i][0]+'" data-contactvalue="'+$options[i][1]+'">'+$options[i][1]+'</option>';
          }
          $productSelectbox.empty();
          $productSelectbox.append(html);
        }

        function convertProductOptions($section){
          // Filter the response from the Ajax response to the usable product id's and names
          var productOptions = [];
          $section.find('.select-product-option').each(function(e){
            var option = [];
            option.push($(this).data('postid'));
            option.push($(this).data('postname'));

            productOptions.push(option);
            $(this).remove();
          });
          if(productOptions.length !== 0){
            fillProductOptions(productOptions);
          }
        }

        function showBestanden($section) {
          // Determine wether to show the bestanden section
          if(!downloadOption.bestandenLoaded && (!downloadOption.isProductPage || downloadOption.bestandtype === '1' )){
            // If the function was called outside of the Ajax function, fire the Ajax function to get the producten
            productenHandler();
          }
          // Only show bestanden section
          hideContactForm($section);

          // Show and scroll to the bestanden section if the bestanden are loaded otherwise make sure the section isnt shown
          if((downloadOption.isProductPage || downloadOption.stap >= 2) && downloadOption.bestandenLoaded){
            $section.next('section.section').show();
            scrollToElement($('#container-async'));
          }else {
            $section.next('section.section').hide();
          }
        }

        function showContactForm($section) {
          if(!bestandtypeToShow.includes(downloadOption.bestandtype) && $('.no-file-found').length) {$('.no-file-found').remove();}
          // Show/hide contact form depending on the downloadOption.stap
          $section.next('section.section').hide();
          if(downloadOption.stap == 3) {
            $section.find('.download-tool .download-tool__contact-form').show();
          }else {
            hideContactForm($section);
          }
        }

        function hideContactForm($section) {
          if($('.no-file-found').length) $('.no-file-found').remove();
          $section.find('.download-tool .download-tool__contact-form').hide();
        }

        function bestandtypeHandler($section) {
          // Only show bestanden if bestandtype is brochure
          if(bestandtypeToShow.includes(downloadOption.bestandtype)) {
            showBestanden($section);
          }else {
            showContactForm($section);
          }
        }

        function handleEmptyBestanden() {
        // Show message if Ajax response returned with no bestanden
          var $downloadTool = $('.download-tool'),
              $section = $downloadTool.closest('section.section'),
              $productElem = $downloadTool.find('*[name="product"]'),
              product = ($productElem.attr('type') == 'hidden' ? $productElem : $productElem.find(':selected')),
              productName = product.data('contactvalue'),
              html = '<div class="no-file-found"><p><strong>Geen resultaten gevonden.</strong><br> Neem hieronder contact met ons op.</p></div>';

          if($('.no-file-found').length == 0) {
            $downloadTool.find('.download-tool__contact-form').before(html);
          }else {
            $('.no-file-found .productnaam').html(productName);
          }

          showContactForm($section);
        }

        function categorieHandler() {
          // get Ajax call for producten options
          if(downloadOption.categorie) {
            $params    = {
              'posttype' : 'product',
              'action' : 'do_filter_posts',
              'tax'  : 'producttype',
              'term' : downloadOption.categorie,
              'getProducten' : true
            };
            if($('.download-tool').find('.product-bestand[name="product"]').length){
              $params['hoofdproduct'] = $('.download-tool').find('.product-bestand[name="product"]').val()
            }
            get_posts($params);
          }
        }

        function productenHandler() {
          // get Ajax call for producten bestanden
          if(downloadOption.product) {
            $params    = {
              'posttype' : downloadOption.bestandtype === '1'? 'product':'model' ,
              'action' : 'do_filter_posts',
              'id' : downloadOption.product,
              'getBestanden' : true,
              'bestandtype' : downloadOption.bestandtype,
            };
            if($('.download-tool').find('.product-bestand[name="product"]').length){
              $params['hoofdproduct'] = $('.download-tool').find('.product-bestand[name="product"]').val()
            }
            get_posts($params);
          }
        }

        $(document).on('change','.bestand-select select[name="model_productgroep"]',function(){
          clearAjaxProducts();
          get_posts({'modelgroepen':$(this).val(), 'action' : 'do_create_filter_selectboxes','hoofdproduct':$('.download-tool').find('.product-bestand[name="product"]').val()});
        });

        function get_models_from_eigenschappen() {
          $eigenschappen = [];
          $('.eigenschappen-inputs-container').find('select,input').each(function(){
            $selectName = $(this).attr('name').replace('productgroep-','');
            $selectValue = $(this).val();
            $eigenschappen += $selectName +'|'+ $selectValue+';';
          });

          $params    = {
            'posttype' : downloadOption.bestandtype === '1'? 'product':'model',
            'action' : 'do_filter_posts',
            'tax'  : 'producttype',
            'term' : downloadOption.categorie,
            'getProducten' : true,
            'eigenschappen' : $eigenschappen
          };
          if($('.download-tool').find('.product-bestand[name="product"]').length){
            $params['hoofdproduct'] = $('.download-tool').find('.product-bestand[name="product"]').val()
          }
          downloadOption.stap = 3;
          get_posts($params);
        }

        $(document).on('change','.eigenschappen-inputs-container select',function(){
          var $container = $(this).closest('.eigenschap-select');
          if($container.next('.eigenschap-select').length === 0 || $container.parent().find('.eigenschap-select:hidden').length === 0){
            get_models_from_eigenschappen();
          }else {
            $container.next('.eigenschap-select').show();
          }
        });

        function fillInitialValues() {
          // Set product and categorie options in the downloadOption var and the contact form if download tool is called from a product page
          $('.product-bestand').each(function(){
            var $element = $(this),
            elem_name = $(this).attr('name');
            downloadOption[elem_name] = $element.val();
            $('.download-tool__contact-form input[name="'+elem_name+'"]').val($element.data('contactvalue'));
          });
        }

        $('.download-tool select').on('change',function(e){
          // Check each download tool selectbox for changes
          var $element                 = $(e.target),
          $section                 = $element.closest('section.section'),
          elem_name                = $element.attr('name'),
          is_bestandTypeSelect     = (elem_name === 'bestandtype'),
          is_categorieSelect       = (elem_name === 'categorie'),
          is_productSelect         = (elem_name === 'product'),
          is_productPaginaTool     = $('.product-bestand').length;

            
          if(is_bestandTypeSelect) {
            if(downloadOption[elem_name] != $element.val()){
              downloadOption.bestandenLoaded = false;
              downloadOption.stap = 0;
            }
          }
          if(!is_productSelect) {
            downloadOption.bestandenLoaded = false;
          }

          // Set downloadOption and contactform values at the corresponding names from the changed selectbox
          downloadOption[elem_name] = $element.val();
          $('.download-tool__contact-form input[name="'+elem_name+'"]').val($element.find(':selected').data('contactvalue'));


          if(is_productPaginaTool){
            downloadOption.isProductPage = true;
            // Set product and categorie options in the downloadOption var and the contact form if download tool is called from a product page
            if(downloadOption.stap === 0){
              fillInitialValues();
              if(downloadOption.bestandtype === '2' ){
                $productgroepen = $('input.product-bestand[name="productgroepen"]').val();
                console.log($productgroepen);
                console.log($('.download-tool').find('.product-bestand[name="product"]').val())
                if($productgroepen){
                  get_posts({'modelgroepen':$productgroepen, 'action' : 'do_create_filter_selectboxes', 'hoofdproduct':$('.download-tool').find('.product-bestand[name="product"]').val() });
                }else {
                  downloadOption.stap = 3
                  handleEmptyBestanden();
                  return;
                }
              }else {
                $('.eigenschappen-inputs-container > *').remove();
                $('.producten-select-container').val("").hide();
              }
            }
          }
          if(!is_productPaginaTool){
            // Only increase downloadOption.stap if not on product pagina since stap already at 3 as defined above
            if(
              is_bestandTypeSelect && downloadOption.stap < 1 ||
              is_categorieSelect && downloadOption.stap < 2 ||
              is_productSelect && downloadOption.stap < 3 
              ) {
              downloadOption.stap++;
            }else if(is_categorieSelect && downloadOption.stap == 3) {
              // rRset product selection and jump back to stap 2, because a different productcategory is chosen
              downloadOption.stap = 2;
              downloadOption.product = '';
              $('.download-tool__contact-form input[name="product"]').val('');
              // Fire contactform handler
              showContactForm($section);
            }

            if(is_bestandTypeSelect) {
              
              if($(this).val() === '1') {
                $hideSelect = $('.document-select');
                $showSelect = $('.brochure-select');
                $('.eigenschap-select').remove();
                $('input[type="hidden"][name^="productgroep"]').remove();
              }else if($(this).val() === '2') {
                $hideSelect = $('.brochure-select');
                $showSelect = $('.document-select');
              }
              $('.bestand-select select[name="product"]').closest('.bestand-select').hide();
              $hideSelect.removeClass('row').removeClass('bestand-select').addClass('hidden');
              $hideSelect.hide();
              $hideSelect.find('select').val("");
              $showSelect.addClass('row').addClass('bestand-select').removeClass('hidden');
              $showSelect.show();              
              $showSelect.find('select').val("");
            }
          }

          // Fire corresponding handler based on selectbox name defined in the vars at the beginning of this function
          if(is_bestandTypeSelect) {
            bestandtypeHandler($section);
          }else if(is_categorieSelect) {
            bestandtypeHandler($section);
            categorieHandler();
          }else if(is_productSelect) {
            if(bestandtypeToShow.includes(downloadOption.bestandtype)) {
              productenHandler();
            }
          }

        });


          // JavaScript to be fired on all pages, after page specific JS is fired
          $('html').on('click','.download-popup-link',function(e){
            var $container = $(this).closest('.download-box'),
                $downloadLink = $container.find('.file-download'),
                $downloadPopupLink = $container.find('.file-popup');

            e.preventDefault();

            if(document.cookie.indexOf('filedownload') !== -1){
              $downloadLink.trigger('click')[0].click();
            }else{
              $downloadPopupLink.trigger('click');
              $('#download-popup .download-button').attr('href',($downloadLink.attr('href')));
              $('#download-popup').find('input[name="bestand"]').val($downloadLink.attr('download'));
            }
          });

          document.addEventListener( 'wpcf7mailsent', function( event ) {
            if ( event.detail.contactFormId === 2626 ) {
              document.cookie = "filedownload=true";
              $('#download-popup .download-button')[0].click();
              $('#download-popup').find('button[data-dismiss="modal"]').trigger('click');
            }
          });


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

